// import React from "react";
import { graphql, useStaticQuery } from "gatsby";

export default function useMenu() {
  const { allData } = useStaticQuery(
    graphql`
      query {
        allData {
          edges {
            node {
              items {
                name
                link
                menu
                
              }
            }
          }
        }
      }
    `
  );
  // subMenu {
  //   name
  //   link
  // }

  //for the submenu update later
  // subMenu {
  //   name
  //   link
  // }

  //for menu.json
  // {
  //   "name": "Support Services",
  //   "menu": "services",
  //   "link": "/services/support-services/",
  //   "subMenu": [
  //     {
  //       "name": "Cloud",
  //       "link": "/services/support-services/cloud/"
  //     },
  //     {
  //       "name": "IBM Sterling",
  //       "link": "/services/support-services/ibm-sterling/"
  //     }
  //   ]
  // },

  let menuObj = {};
  let menuArrayPropNames = {};
  // let menuObj = {
  //   services: [],
  //   industries: [],
  //   insights: [],
  //   company: [],
  //   contact: {}
  // };


  allData.edges[0].node.items.map(item => {
    if (item.menu) {
      menuObj[item.menu] = menuObj[item.menu] || [];
      menuArrayPropNames[item.menu] = menuArrayPropNames[item.menu] || [];
      menuObj[item.menu].push(item)
    } else {
      menuObj[item.name] = item;
    }
  })


  //sorting
  for (let [key, menu] of Object.entries(menuObj)) {
    if(Array.isArray(menu)) {
      menuObj[key] = menu.sort((a, b) => {
        return (a.name > b.name) ? 1 : -1;
      })
    }
  }

  return [menuObj, Object.keys(menuArrayPropNames)];
}
