import React from "react";

const humanReadableMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export function isDateInFutureOrToday(utcString) {
  /* 
    Parses the UTC date from a string, using the default Date objects parse method,
    and returns a formatted string(format hardcoded) for use in blog. 
  */
  let date;

  //"Publish_Date" data point has a "2020-12-08" format instead of standard Date Object String(ie:Tue Dec 08 2020 14:37:10 GMT-0600 (Central Standard Time) )
  try {
    if (utcString.length === 10) {
      let parseDate = new Date(utcString).toLocaleString("en-US", {
        timeZone: "cet"
      });
      date = new Date(parseDate);
    } else {
      date = new Date(Date.parse(utcString));
    }
    //try catch is a fix for the gatsby build and the logic inside
  } catch (ex) {
    date = new Date();
  }

  //Zeroing out to today.
  if (date > new Date().setHours(0, 0, 0, 0)) {
    return true;
  } else {
    return false;
  }
}

export function formatUTCStringToLocalDateForPost(utcString) {
  /* 
    Parses the UTC date from a string, using the default Date objects parse method,
    and returns a formatted string(format hardcoded) for use in blog. 
  */
  let date;

  //"Publish_Date" data point has a "2020-12-08" format instead of standard Date Object String(ie:Tue Dec 08 2020 14:37:10 GMT-0600 (Central Standard Time) )
  try {
    if (utcString.length === 10) {
      let parseDate = new Date(utcString).toLocaleString("en-US", {
        timeZone: "cet"
      });
      date = new Date(parseDate);
    } else {
      date = new Date(Date.parse(utcString));
    }
    //try catch is a fix for the gatsby build and the logic inside
  } catch (ex) {
    date = new Date();
  }

  const dayOfMonth = date.getDate();
  const month = humanReadableMonths[date.getMonth()];
  const year = date.getFullYear();

  return `${month} ${dayOfMonth}, ${year}`;
}

export function parseNumber(numberStr) {
  let str = "";

  for (let ch of numberStr) {
    if (ch.match(/\d/) || ch.match(/\./)) {
      str += ch;
    }
  }

  return parseFloat(str);
}

export function exists(x) {
  return x != null;
}

// https://davidwalsh.name/javascript-debounce-function
/**
 * Reduces the frequency of expensive actions,
 * by introducing a period after the function has been called, set in ms,
 * during which the function cannot be called again.
 * Notably, this is for side-effecting functions only,
 * as the returned function does not return the result of the passed in function
 *
 * @param {function} func The function to be called.
 * @param {number} wait The delay to be introduced between function calls.
 * @param {boolean} immediate Boolean indicating if delay should precede first call of function.
 */
export function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

/**
 * Custom Renderer for links within Markdown for use within React-Markdown.
 */
export function LinkRenderer({ href, children }) {
  return (
    <a href={href} target="_blank" rel="noopener nofollow noreferrer">
      {children}
    </a>
  );
}

export function addTrailingSlash(str) {
  return str.endsWith("/") ? str : `${str}/`;
}
