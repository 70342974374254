import React, { useEffect } from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import useMenu from "../../../../utils/useMenu";

//components
import ContactUs from "../../../panels/ContactUs";

//style
import s from "./Footer.module.scss";
import { addTrailingSlash } from "../../../../utils/utilities";

/* 
  XOverflow triggered when viewport shrinks is because footer is not mobile responsive.
*/

export default function Footer({ social, logo, isLandingPage }) {
  const [menu, menuArrayPropNames] = useMenu();

  useEffect(() => {
    // console.log("hit");
    const script1 = document.createElement("script");
    script1.type = "text/javascript";
    script1.appendChild(
      document.createTextNode(`
      var _iub = _iub || [];
    _iub.csConfiguration = {"enableCcpa":true,"tcfPurposes":{"2":"consent_only","3":"consent_only","4":"consent_only","5":"consent_only","6":"consent_only","7":"consent_only","8":"consent_only","9":"consent_only","10":"consent_only"},"isTCFConsentGlobal":false,"consentOnContinuedBrowsing":false,"perPurposeConsent":true,"enableCMP":true,"googleAdditionalConsentMode":true,"ccpaAcknowledgeOnDisplay":false,"whitelabel":false,"lang":"en","siteId":2053600,"floatingPreferencesButtonDisplay":"bottom-left","cookiePolicyId":12542728, "banner":{ "acceptButtonDisplay":true,"customizeButtonDisplay":true,"rejectButtonDisplay":true,"position":"bottom" }};
    `)
    );
    document.body.appendChild(script1);

    const script2 = document.createElement("script");
    script2.src = "//cdn.iubenda.com/cs/tcf/stub-v2.js";
    script2.type = "text/javascript";
    document.body.appendChild(script2);

    const script5 = document.createElement("script");
    script5.src = "//cdn.iubenda.com/cs/tcf/safe-tcf-v2.js";
    script5.type = "text/javascript";
    document.body.appendChild(script5);

    const script3 = document.createElement("script");
    script3.src = "//cdn.iubenda.com/cs/ccpa/stub.js";
    script3.type = "text/javascript";
    document.body.appendChild(script3);

    const script4 = document.createElement("script");
    script4.src = "//cdn.iubenda.com/cs/iubenda_cs.js";
    script4.type = "text/javascript";
    script4.async = true;
    document.body.appendChild(script4);

    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
      document.body.removeChild(script3);
      document.body.removeChild(script4);
      document.body.removeChild(script5);
    };
  }, []);

  const year = new Date().getFullYear();

  return (
    <>
      {isLandingPage ? null : <ContactUs />}
      <footer className={s.footer}>
        <div className={s.mainRow}>
          <div className={s.info}>
            <Link className={s.logoLink} to="/">
              <Img fluid={logo} alt="Nitco Logo" />
            </Link>
            <div className={s.details}>
              <a
                href={
                  "https://www.google.com/maps/place/Nitco+Inc/@29.7750387,-95.7836527,15z/data=!4m5!3m4!1s0x0:0x179870ae18746b95!8m2!3d29.7750387!4d-95.7836527?shorturl=1"
                }
                target="_blank"
                rel="noopener nofollow noreferrer"
                className={s.detailsAddress}
              >
                440 Cobia Dr., Suite 1701
                <br />
                Katy, Texas 77494
              </a>
              <br />
              {/* <a href={"https://www.google.com/maps/place/Nitco+Inc/@29.7750387,-95.7836527,15z/data=!4m5!3m4!1s0x0:0x179870ae18746b95!8m2!3d29.7750387!4d-95.7836527?shorturl=1"} className={s.detailsAddress}>Katy, Texas 77494</a> */}
              <p className={s.detailsHours}>Mon - Fri, 9am - 6pm </p>
              <a
                href={"tel:+1-281-503-7002"}
                className={s.detailsPhone}
                target="_blank"
                rel="noopener nofollow noreferrer"
              >
                281-503-7002
              </a>
              <br />
              <a
                href={`mailto:YourPartner@nitcoinc.com `}
                style={{ textTransform: "none" }}
                target="_blank"
                rel="noopener nofollow noreferrer"
              >
                YourPartner@nitcoinc.com
              </a>
            </div>
            <div className={s.socialLinks}>
              <a
                href={social.twitter}
                target="_blank"
                rel="noopener nofollow noreferrer"
              >
                <i className="fab fa-twitter" style={{ color: "#1DA1F2" }}></i>
              </a>
              <a
                href={social.facebook}
                target="_blank"
                rel="noopener nofollow noreferrer"
              >
                <i className="fab fa-facebook" style={{ color: "#4267B2" }}></i>
              </a>
              <a
                href={social.linkedin}
                target="_blank"
                rel="noopener nofollow noreferrer"
              >
                <i className="fab fa-linkedin" style={{ color: "#0e76a8" }}></i>
              </a>

              <a
                href={social.youtube}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-youtube" style={{ color: "#FF0000" }}></i>
              </a>
            </div>
          </div>
          <div className={s.menu}>
            {menuArrayPropNames.map((mName, i) => {
              return (
                <div className={`${s.submenu} ${s.services}`}>
                  <h4>{mName}</h4>
                  <ul>
                    {menu[mName].map((m, i) => (
                      <li key={i}>
                        {m.subMenu ? (
                          <>
                            <p className={`mb-1`}>{m.name}</p>
                            <ul>
                              {m.subMenu.map((subMenu, si) => (
                                <li>
                                  <Link
                                    to={subMenu.link}
                                    activeClassName={s.activeLink}
                                  >
                                    {subMenu.name}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </>
                        ) : (
                          <Link
                            to={addTrailingSlash(m.link)}
                            activeClassName={s.activeLink}
                          >
                            {m.name}
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
        <p className={s.copyrightStatement}>
          &copy; {year},{" "}
          <Link className={s.link} to="/">
            Nitco, Inc.
          </Link>{" "}
          All Rights Reserved.
        </p>

        <p className={s.copyrightStatement}>
          <Link className={s.link} to="/privacy-policy">
            Privacy Policy
          </Link>{" "}
          |{" "}
          <Link className={s.link} to="/cookie-policy">
            Cookie Policy
          </Link>
        </p>
        {/* <script src={"../../../static/chatbot/js/main.9ca37a8c.js"}></script> */}
      </footer>
      <br />
      <br />
    </>
  );
}
