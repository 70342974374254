import React, { useEffect, useRef } from "react";

import s from "./Hamburger.module.scss";

const noop = () => {};

export default function Hamburger({ isVisible, isDrawerOpen, toggleDrawer, strapiHomepageMainEvents, location }) {
  const win = useRef(null);

  useEffect(() => {
    win.current = window;
  }, []);

  return (
    <div
      className={`${s.hamburgerMenu} ${isVisible ? s.visible : ""} ${
        isDrawerOpen ? s.open : ""
      } ${strapiHomepageMainEvents && strapiHomepageMainEvents.shouldShowBar && location && location.pathname === '/' ? s.withMainEvent : ""}`}
      onClick={
        isVisible || (win.current && win.current.innerWidth <= 1000)
          ? toggleDrawer
          : noop
      }
    >
      <div></div>
    </div>
  );
}
