import React from "react";
import { useStaticQuery, graphql, Link, navigate } from "gatsby";

import s from "../layouts/original/footers/Footer.module.scss";

export default ({ _onClick }) => {
  const {
    allStrapiHomePage: { edges }
  } = useStaticQuery(graphql`
    query {
      allStrapiHomePage(sort: { fields: [created_at], order: DESC }, limit: 1) {
        edges {
          node {
            main_cover_image {
              childImageSharp {
                fluid(maxWidth: 1140) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  const backgroundImage =
    edges[0].node.main_cover_image.childImageSharp.fluid.src;

  return (
    <>
      <div className={`container-fluid`}>
        <div className={`container`}>
          <div className={`row`}>
            <div className={`col-sm-12`}>
              <div
                className={s.contactUsBox}
                style={{ backgroundImage: `url(${backgroundImage})`, cursor: "pointer" }}
                onClick={() => {
                  if (_onClick) {
                    _onClick();
                  }

                  navigate("/contact/");
                }}
              >
                <div className={`${s.overlay}`}>
                  <h3 className={`mb-1`}>We'd love to hear from you!</h3>
                  <Link
                    to={"/contact/"}
                    className={`btn btn-primary btn-lg`}
                    onClick={() => {
                      if (_onClick) {
                        _onClick();
                      }
                    }}
                  >
                    CONTACT US
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// function ContactUs() {
//   return (
//     <div className={`container-fluid`}>
//       <div className={`container`}>
//         <div className={`row`}>
//           <div className={`col-sm-12`}>
//             <div
//               className={s.contactUsBox}
//               style={{ backgroundImage: `url(${ContacUsBanner2})` }}
//             >
//               <h3>We'd love to hear from you!</h3>
//               <Link to={"/contact"} className={`btn btn-primary btn-lg`}>
//                 CONTACT US
//               </Link>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ContactUs;
