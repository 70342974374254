import React, { useEffect } from "react";

import s from "./SideDrawer.module.scss";

// keyup handler hof
function makeEscapeKeypressHandler(action) {
  return function escapeKeypressHandler(event) {
    if (event.key === "Escape") {
      action(event); // In this case event is ignored.
    }
  };
}

export default function SideDrawer({ children, isDrawerOpen, closeDrawer, location }) {
  // Side-drawer should close if escape is pressed, or if dark section of the overlay is clicked.
  useEffect(() => {
    const esc = makeEscapeKeypressHandler(closeDrawer);
    window.addEventListener("keyup", esc);

    return () => window.removeEventListener("keyup", esc);
  }, [closeDrawer]);

  //close side draw if page change
  useEffect(() => {
    if (isDrawerOpen && location) {
      closeDrawer()
    }
  }, [location])

  return (
    <div
      className={`${s.sdContainer} ${isDrawerOpen ? s.visible : ""}`}
      onClick={closeDrawer}
    >
      <aside className={s.sideDrawer} onClick={e => e.stopPropagation()}>
        {children}
      </aside>
      <div className={s.overlay}></div>
    </div>
  );
}
