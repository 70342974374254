import React from "react";
import BackgroundImage from "gatsby-background-image";
import { Container } from "react-bootstrap";

//styles
import s from "./PanelFoundation.module.scss";

function PanelFoundation({
  //level 1
  id,
  rootClassName,
  rootStyle,
  rootGatsbyBackgroundImageFluid,
  //level 2
  overlay,
  overlayStyle,
  //"overlayColor" is attached to overlayStyle.backgroundColor as an override
  overlayColor,
  //level 3
  rowClassName,
  rowStyle,
  //level 4
  children
}) {
  const BackgroundImageLoader = ({ children }) => {
    const rootProps = {
      className: `container-fuild ${s.relative} ${rootClassName || ""}`,
      style: rootStyle || {}
    };

    if (id) {
      rootProps.id = id;
    }

    if (rootGatsbyBackgroundImageFluid) {
      return (
        <BackgroundImage
          id="panelFoundationBg"
          {...rootProps}
          fluid={rootGatsbyBackgroundImageFluid}
        >
          {children}
        </BackgroundImage>
      );
    } else {
      return <div {...rootProps}>{children}</div>;
    }
  };

  //override: overlay can change color with overlayColor prop
  if (overlayColor) {
    overlayStyle = overlayStyle || {};
    overlayStyle.backgroundColor = overlayColor;
  }

  return (
    <BackgroundImageLoader>
      <div
        className={`${overlay || overlayColor ? s.overlay : ""}`}
        style={overlayStyle || {}}
      >
        <Container>
          <div className={`row ${rowClassName || ""}`} style={rowStyle || {}}>
            {children}
          </div>
        </Container>
      </div>
    </BackgroundImageLoader>
  );
}

export default PanelFoundation;
