import "./src/components/layouts/original/layout.scss";
import "./src/components/mailchimp.classic-10-7.css";
// import "./node_modules/bootstrap/scss/bootstrap.scss"

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// You can delete this file if you're not using it

function getInternetExplorerVersion() {
  /* From so -- https://stackoverflow.com/questions/17907445/how-to-detect-ie11 */
  let ua, re;
  let rv = -1;
  if (navigator.appName == "Microsoft Internet Explorer") {
    ua = navigator.userAgent;
    re = new RegExp("MSIE ([0-9]{1,}[\\.0-9]{0,})");
    if (re.exec(ua) != null) rv = parseFloat(RegExp.$1);
  } else if (navigator.appName == "Netscape") {
    ua = navigator.userAgent;
    re = new RegExp("Trident/.*rv:([0-9]{1,}[\\.0-9]{0,})");
    if (re.exec(ua) != null) rv = parseFloat(RegExp.$1);
  }
  return rv;
}

/* 
  TODO: This will have to be tested at a later date.
*/
export const onClientEntry = () => {
  if (getInternetExplorerVersion() === 11) {
    const s = document.createElement("script");
    s.src = "/ie11CustomProperties.js";
    s.async = true;
    s.onerror = console.error("Could not polyfill CSS Properties.");
    document.head.appendChild(s);
  }

  /* eslint-disable no-unused-expressions */
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
  }
  /* eslint-enable no-unused-expressions */

  /* eslint-disable no-undef */
  (function(w, d, t) {
    window._ml = w._ml || {};
    _ml.eid = "81430";
    _ml.cid = "7ff1b185-525e-4451-9da5-97b1ce973b1d";
    var s, cd, tag;
    s = d.getElementsByTagName(t)[0];
    cd = new Date();
    tag = d.createElement(t);
    tag.async = 1;
    tag.src = "https://ml314.com/tag.aspx?" + cd.getDate() + cd.getMonth();
    s.parentNode.insertBefore(tag, s);
  })(window, document, "script");
  /* eslint-enable no-undef */

  // Enable embed tracking pixel for AppZen mastermind event.
  (() => {
    // Init pixel w/ src.
    const pixelImg = document.createElement("img");
    pixelImg.src =
      "https://dc.ads.linkedin.com/collect/?pid=7850&conversionId=418177&fmt=gif";

    // Set physical attrs.
    pixelImg.setAttribute("height", "1");
    pixelImg.setAttribute("width", "1");
    pixelImg.setAttribute("style", "display:none;");

    // Explicitly add empty alt.
    pixelImg.setAttribute("alt", "");

    // Add tracking pixel to document.
    document.body.prepend(pixelImg);
  })();
};
