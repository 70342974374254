import React, { useRef, useEffect } from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import useForceUpdate from "use-force-update";
import { Navbar, Nav } from "react-bootstrap";
import { NavDropdownMenu, DropdownSubmenu } from "react-bootstrap-submenu";

// custom hooks
import useIntersect from "../../../../utils/useIntersect";
import useMenu from "../../../../utils/useMenu";

// Components
import Hamburger from "./Hamburger";

// styles
import s from "./TopNav.module.scss";
import { prop } from "ramda";

export default function TopNav({
  isDrawerOpen,
  toggleDrawer,
  social,
  logo,
  pageData,
  strapiHomepageMainEvents,
  location,
  isLandingPage
}) {
  // * Get Menu data from static query in custom hook
  const [menu, menuArrayPropNames] = useMenu();

  // * Create ref to self for use with interaction observer.
  const nav = useRef(null);

  // * Call intersection observer hook.
  const [setNode, intersect] = useIntersect({
    rootMargin: "0%",
    threshold: 0
  });

  const forceUpdate = useForceUpdate();

  const activateInsights = (firstPartOfUrl, defaultValue) => {
    switch (firstPartOfUrl) {
      case "blog":
        return "active";
      case "case-studies":
        return "active";
      case "news":
        return "active";
      case "webinar":
        return "active";
      case "whitepapers":
        return "active";
      default:
        return "";
    }
  };

  const activateSubMenu = (pageData, menu) => {
    return pageData && pageData.pathname.split("/")[2] === menu.split("/")[2]
      ? "active"
      : "";
  };

  // * Pass reference to nav element to intersection observer hook.
  useEffect(() => {
    setTimeout(() => {
      nav.current = document.querySelector(`.navbar`);
      setNode(nav.current);
    }, 250);
  }, [setNode]);

  if (isLandingPage)
    return (
      <Navbar bg="white" expand="lg">
        <div className={`container`}>
          <Link className={`navbar-brand ${s.logoLink}`} to="/">
            <Img className={s.logo} fluid={logo} alt="Nitco. Inc. Logo" />
          </Link>
          <Hamburger
            isVisible={isLandingPage}
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={toggleDrawer}
            strapiHomepageMainEvents={strapiHomepageMainEvents}
            location={location}
          />
        </div>
      </Navbar>
    );

  return (
    <Navbar bg="white" expand="lg">
      <div className={`container`}>
        <Link className={`navbar-brand ${s.logoLink}`} to="/">
          <Img className={s.logo} fluid={logo} alt="Nitco. Inc. Logo" />
        </Link>
        <Nav className={`mr-auto ${s.visibleLg}`}>
          {menuArrayPropNames.map((m, i) => {
            let shouldBeActive;

            if (m === "insights") {
              shouldBeActive =
                pageData && activateInsights(pageData.pathname.split("/")[1]);
            } else {
              // console.dir(menu);
              shouldBeActive =
                pageData && menu[m][0].menu === pageData.pathname.split("/")[1]
                  ? "active"
                  : "";
              // console.log(shouldBeActive)
              // console.log(pageData)
            }

            return (
              <NavDropdownMenu
                title={m}
                id={`${m}-dropdown`}
                className={`${shouldBeActive}`}
                renderMenuOnMount={true}
              >
                {menu[m].map(({ name, link, subMenu }) => (
                  <>
                    {subMenu ? (
                      <DropdownSubmenu
                        title={name}
                        className={`dropdown-submenu-container ${activateSubMenu(
                          pageData,
                          link
                        )}`} // dropdown-item dropdown-submenu dropdown-toggle
                      >
                        {subMenu.map((s, index) => {
                          return (
                            <Link
                              className={`dropdown-item`}
                              to={s.link}
                              activeClassName={`active text-white`}
                              partiallyActive={true}
                              onClick={() => forceUpdate()}
                            >
                              {s.name}
                            </Link>
                          );
                        })}
                      </DropdownSubmenu>
                    ) : (
                      <Link
                        className={`dropdown-item`}
                        to={link}
                        activeClassName={`active text-white`}
                        partiallyActive={true}
                        onClick={() => forceUpdate()}
                      >
                        {name}
                      </Link>
                    )}
                  </>
                ))}
              </NavDropdownMenu>
            );
          })}
          <div className={` nav-item ${s.navLink} `}>
            <Link
              className={`nav-link`}
              to="/contact/"
              activeClassName={"active"}
            >
              Contact
            </Link>
          </div>
        </Nav>
        <Nav className={`ml-auto ${s.visibleLg}`}>
          <ul className={s.socialIcons}>
            <li className={s.socialIcon}>
              <a
                href={social.twitter}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter" style={{ color: "#1DA1F2" }}></i>
              </a>
            </li>
            <li className={s.socialIcon}>
              <a
                href={social.facebook}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook" style={{ color: "#4267B2" }}></i>
              </a>
            </li>
            <li className={s.socialIcon}>
              <a
                href={social.linkedin}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin" style={{ color: "#0e76a8" }}></i>
              </a>
            </li>
            <li className={s.socialIcon}>
              <a
                href={social.youtube}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-youtube" style={{ color: "#FF0000" }}></i>
              </a>
            </li>
          </ul>
        </Nav>
        <Hamburger
          isVisible={intersect.time && !intersect.isIntersecting}
          isDrawerOpen={isDrawerOpen}
          toggleDrawer={toggleDrawer}
          strapiHomepageMainEvents={strapiHomepageMainEvents}
          location={location}
        />
      </div>
    </Navbar>
    // <nav className={s.topNav}>
    //   <div className={s.contentBox}>
    //     <Link className={s.logoLink} to="/">
    //       <Img className={s.logo} fluid={logo} alt="Nitco. Inc. Logo" />
    //     </Link>
    //     <ul className={s.menu}>
    //       {menuArrayPropNames.map((m, i) => {
    //         return (
    //           <li
    //             className={`${s.menuItem} ${
    //               menu[m].find(({ link }) => pageData && pageData.pathname === link)
    //                 ? s.active
    //                 : ""
    //             }`}
    //           >
    //             <span className={s.menuItemLabel}>{m}</span>
    //             <ul className={s.submenu}>
    //               {menu[m].map(({ name, link }) => (
    //                 <li
    //                   key={link}
    //                   className={`${s.submenuItem} ${
    //                     pageData && pageData.pathname === link
    //                       ? s.submenuActive
    //                       : ""
    //                   }`}
    //                 >
    //                   <Link className={s.menuItemLink} to={link}>
    //                     {name}
    //                   </Link>
    //                 </li>
    //               ))}
    //             </ul>
    //           </li>
    //         );
    //       })}
    //       <li
    //         className={`${s.menuItem} ${
    //           pageData && pageData.pathname === menu.contact.link
    //             ? s.active
    //             : ""
    //         }`}
    //       >
    //         <Link className={s.menuItemLink} to={menu.contact.link}>
    //           {menu.contact.name}
    //         </Link>
    //       </li>
    //     </ul>
    //     <ul className={s.socialIcons}>
    //       <li className={s.socialIcon}>
    //         <a href={social.twitter}>
    //           <i className="fab fa-twitter"></i>
    //         </a>
    //       </li>
    //       <li className={s.socialIcon}>
    //         <a href={social.facebook}>
    //           <i className="fab fa-facebook"></i>
    //         </a>
    //       </li>
    //       <li className={s.socialIcon}>
    //         <a href={social.linkedin}>
    //           <i className="fab fa-linkedin"></i>
    //         </a>
    //       </li>
    //     </ul>
    //   </div>
    // <Hamburger
    //   isVisible={intersect.time && !intersect.isIntersecting}
    //   isDrawerOpen={isDrawerOpen}
    //   toggleDrawer={toggleDrawer}
    //   strapiHomepageMainEvents={strapiHomepageMainEvents}
    //   location={location}
    // />
    // </nav>
  );
}
