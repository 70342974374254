import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import useMenu from "../../../../utils/useMenu";
import ContacUsBanner2 from "../../../../images/contact-us-banner-2.png";

import PanelFoundation from "../../../panels/PanelFoundation";

import s from "./SideMenu2.module.scss";
import ContactUs from "../../../panels/ContactUs";

function SideMenu2({ logo, social, pageData, closeDrawer }) {
  const [menu, menuArrayPropNames] = useMenu();

  return (
    <PanelFoundation
      rootClassName={`pt-5`}
      rootStyle={{ paddingBottom: "50px" }}
    >
      <div className={`col-sm-12`}>
        <div>
          <Link to={"/"} onClick={() => closeDrawer()} className={`${s.logo}`}>
            <Img fluid={logo} alt="Nitco Logo" className={`${s.logo} mb-5`} />
          </Link>
        </div>
      </div>

      {menuArrayPropNames.map((mName, i) => {
        return (
          <div className={`col-md-6 mb-5 ${s.submenu}`}>
            <ul class={`list-group ml-0 pl-0`}>
              <li className={`bg-secondary text-white p-3`}>
                <h4 className={`text-white`}>{mName}</h4>
              </li>
              {menu[mName].map((m, i) => (
                <li key={i}>
                  {m.subMenu ? (
                    <div className={`list-group-item`}>
                      <p>{m.name}</p>

                      <ul className={`pl-5`}>
                        {m.subMenu.map((sub, index) => {
                          return (
                            <li>
                              <Link
                                to={sub.link}
                                className={`list-group-item`}
                                activeClassName={`active`}
                                partiallyActive={true}
                                onClick={() => closeDrawer()}
                              >
                                {sub.name}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : (
                    <Link
                      to={m.link}
                      className={`list-group-item`}
                      activeClassName={`active`}
                      partiallyActive={true}
                      onClick={() => closeDrawer()}
                    >
                      {m.name}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
        );
      })}

      <ContactUs _onClick={closeDrawer} />
    </PanelFoundation>
  );
}

export default SideMenu2;
