import { useState, useRef } from "react";
import { useEffect } from "react";

export default function useIntersect({
  root = null /* null means default of viewport */,
  rootMargin,
  threshold = 0
}) {
  // When intersection is invoked it will update the state using this hook.
  // entry is passed out of the hook to have access to the state.
  const [entry, updateEntry] = useState({});

  // Represents the node that the intersection will observe.
  // setNode is passed out of the hook.
  const [node, setNode] = useState(null);

  // In order not to keep recreating the observer a reference is used.
  const observer = useRef(null);

  // The effect which creates the observer, and returns clean up fn.
  useEffect(() => {
    // if observer is being recalled due to change in node for instance.
    // disconnect the old observer reference.
    if (observer.current) observer.current.disconnect();

    // Set the ref to a new instance of the observer,
    // using the updateEntry state hook update function as the cb,
    // and using the options passed into the hook.
    observer.current = new window.IntersectionObserver(
      ([entry]) => updateEntry(entry),
      {
        root,
        rootMargin,
        threshold
      }
    );

    // destructure a reference to the current observer from the "useRef" reference,
    // React reference is mutable,
    // but we want a ref to be closed over so we don't lose it,
    // so that we can clean up and disconnect.
    const { current: currentObserver } = observer;

    // if node is set, set the node to be observed.
    if (node) currentObserver.observe(node);

    // return clean up function which disconnects the observer.
    return () => currentObserver.disconnect();
  }, [node, root, rootMargin, threshold]);

  // return functions to set node, and to keep track of entry state.
  return [setNode, entry];
}
