import React, { useState, useRef, useEffect } from "react";
// import './colors.scss'
import { useStaticQuery, graphql } from "gatsby";
import { enableBodyScroll, disableBodyScroll } from "body-scroll-lock";

import "../../../css/global.css";
import "../../../css/flaticon.css";

// import SEO from "../../seo";
// import MainEventBar from "./headers/MainEventBar";
import TopNav from "./headers/TopNav";
import Footer from "./footers/Footer";
import SideDrawer from "./side/SideDrawer";
import SideMenu from "./side/SideMenu2";

export const layoutContext = React.createContext(null);

export default function Layout({ children, location, pageContext }) {
  const isLandingPage = !!pageContext && !!pageContext.landingPage;

  const body = useRef(null); // reference for body element to disable scrolling on menu open.

  const [pageData, setPageData] = useState(null);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  // Functions to be passed down as props for opening and closing the hamburger.
  const toggleDrawer = () => {
    // if (isDrawerOpen) {
    //   enableBodyScroll(body.current); // toggle scroll on body.
    // } else {
    //   disableBodyScroll(body.current);
    // }

    //body.current.classList.toggle(s.marginRight); // toggle class that adds a 17px margin to account for scrollbar disappearance.
    setDrawerOpen(!isDrawerOpen);
  };

  const closeDrawer = () => {
    if (body) {
      enableBodyScroll(body.current); // enable scroll on body.
    }
    //body.current.classList.remove(s.marginRight); // remove class that adds a 17px margin to account for scrollbar disappearance.
    setDrawerOpen(false);
  };

  // nested destructuring -- might be a little deep, but I think it's ok.
  const {
    site: { siteMetadata },
    file: {
      childImageSharp: { fluid }
    },
    strapiHomepageMainEvents
  } = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "final-logo.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        site {
          siteMetadata {
            twitter
            facebook
            linkedin
            youtube
          }
        }
        strapiHomepageMainEvents {
          id
          shouldShowBar
          heading
          link
          buttonText
        }
      }
    `
  );

  // Get reference to the body element for use in enabling or disabling scroll behaviour when hamburger is toggled.
  useEffect(() => {
    body.current = document.querySelector("body");
  }, []);

  if (isLandingPage)
    return (
      <layoutContext.Provider value={setPageData}>
        {/* <SEO {...pageData} location={location} /> */}
        <SideDrawer
          logo={fluid}
          isDrawerOpen={isDrawerOpen}
          closeDrawer={closeDrawer}
        >
          <SideMenu
            closeDrawer={closeDrawer}
            pageData={pageData}
            logo={fluid}
            social={siteMetadata}
          />
        </SideDrawer>
        <TopNav
          logo={fluid}
          social={siteMetadata}
          isDrawerOpen={isDrawerOpen}
          toggleDrawer={toggleDrawer}
          pageData={pageData}
          location={location}
          isLandingPage={isLandingPage}
        />
        <main>{children}</main>
        <Footer
          isLandingPage={isLandingPage}
          logo={fluid}
          social={siteMetadata}
        />
      </layoutContext.Provider>
    );

  // Markup
  return (
    <layoutContext.Provider value={setPageData}>
      {/* <MainEventBar location={location} data={strapiHomepageMainEvents} /> */}
      {/* <SEO {...pageData} location={location} /> */}
      <SideDrawer
        logo={fluid}
        isDrawerOpen={isDrawerOpen}
        closeDrawer={closeDrawer}
      >
        <SideMenu
          closeDrawer={closeDrawer}
          pageData={pageData}
          logo={fluid}
          social={siteMetadata}
        />
      </SideDrawer>
      <TopNav
        logo={fluid}
        social={siteMetadata}
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        pageData={pageData}
        location={location}
        isLandingPage={isLandingPage}
      />
      <main>{children}</main>
      <Footer logo={fluid} social={siteMetadata} />
    </layoutContext.Provider>
  );
}
