// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-blog-list-v-2-js": () => import("./../src/templates/_Blog/BlogList-v2.js" /* webpackChunkName: "component---src-templates-blog-blog-list-v-2-js" */),
  "component---src-templates-post-view-js": () => import("./../src/templates/PostView.js" /* webpackChunkName: "component---src-templates-post-view-js" */),
  "component---src-templates-news-news-list-js": () => import("./../src/templates/_News/NewsList.js" /* webpackChunkName: "component---src-templates-news-news-list-js" */),
  "component---src-templates-webinar-webinar-list-js": () => import("./../src/templates/_Webinar/WebinarList.js" /* webpackChunkName: "component---src-templates-webinar-webinar-list-js" */),
  "component---src-templates-white-paper-white-paper-list-js": () => import("./../src/templates/_WhitePaper/WhitePaperList.js" /* webpackChunkName: "component---src-templates-white-paper-white-paper-list-js" */),
  "component---src-templates-white-paper-whitepaper-js": () => import("./../src/templates/_WhitePaper/whitepaper.js" /* webpackChunkName: "component---src-templates-white-paper-whitepaper-js" */),
  "component---src-templates-case-study-case-studies-list-js": () => import("./../src/templates/_CaseStudy/CaseStudiesList.js" /* webpackChunkName: "component---src-templates-case-study-case-studies-list-js" */),
  "component---src-templates-making-work-matter-js": () => import("./../src/templates/MakingWorkMatter.js" /* webpackChunkName: "component---src-templates-making-work-matter-js" */),
  "component---src-templates-why-robot-js": () => import("./../src/templates/WhyRobot.js" /* webpackChunkName: "component---src-templates-why-robot-js" */),
  "component---src-templates-thank-you-js": () => import("./../src/templates/ThankYou.js" /* webpackChunkName: "component---src-templates-thank-you-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-about-js": () => import("./../src/pages/company/about.js" /* webpackChunkName: "component---src-pages-company-about-js" */),
  "component---src-pages-company-careers-js": () => import("./../src/pages/company/careers.js" /* webpackChunkName: "component---src-pages-company-careers-js" */),
  "component---src-pages-company-our-team-js": () => import("./../src/pages/company/our-team.js" /* webpackChunkName: "component---src-pages-company-our-team-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-dir-1-js": () => import("./../src/pages/dir/1.js" /* webpackChunkName: "component---src-pages-dir-1-js" */),
  "component---src-pages-explainer-videos-js": () => import("./../src/pages/explainer-videos.js" /* webpackChunkName: "component---src-pages-explainer-videos-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("./../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-public-sector-js": () => import("./../src/pages/services/public-sector.js" /* webpackChunkName: "component---src-pages-services-public-sector-js" */),
  "component---src-pages-services-rpa-as-a-service-js": () => import("./../src/pages/services/rpa-as-a-service.js" /* webpackChunkName: "component---src-pages-services-rpa-as-a-service-js" */),
  "component---src-pages-services-rpa-managed-support-services-js": () => import("./../src/pages/services/rpa-managed-support-services.js" /* webpackChunkName: "component---src-pages-services-rpa-managed-support-services-js" */),
  "component---src-pages-services-staff-augmentation-js": () => import("./../src/pages/services/staff-augmentation.js" /* webpackChunkName: "component---src-pages-services-staff-augmentation-js" */),
  "component---src-pages-services-technology-integration-managed-support-services-js": () => import("./../src/pages/services/technology-integration-managed-support-services.js" /* webpackChunkName: "component---src-pages-services-technology-integration-managed-support-services-js" */),
  "component---src-pages-solutions-artificial-intelligence-js": () => import("./../src/pages/solutions/artificial-intelligence.js" /* webpackChunkName: "component---src-pages-solutions-artificial-intelligence-js" */),
  "component---src-pages-solutions-chatbots-js": () => import("./../src/pages/solutions/chatbots.js" /* webpackChunkName: "component---src-pages-solutions-chatbots-js" */),
  "component---src-pages-solutions-intelligent-automation-js": () => import("./../src/pages/solutions/intelligent-automation.js" /* webpackChunkName: "component---src-pages-solutions-intelligent-automation-js" */),
  "component---src-pages-solutions-intergration-platform-ibm-sterling-js": () => import("./../src/pages/solutions/intergration-platform/ibm-sterling.js" /* webpackChunkName: "component---src-pages-solutions-intergration-platform-ibm-sterling-js" */),
  "component---src-pages-solutions-middleware-integration-js": () => import("./../src/pages/solutions/middleware-integration.js" /* webpackChunkName: "component---src-pages-solutions-middleware-integration-js" */),
  "component---src-pages-solutions-rpa-js": () => import("./../src/pages/solutions/rpa.js" /* webpackChunkName: "component---src-pages-solutions-rpa-js" */)
}

